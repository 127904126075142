import { PostDataType } from "data/types";
import { useState, useEffect } from "react";
import { auth, fs, analytics } from "firebaseSetup"
import { onSnapshot, addDoc, doc, collection, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore"
import { signInAnonymously, signInWithEmailAndPassword } from "firebase/auth"
import { logEvent } from "firebase/analytics";
import { reject } from "lodash";
import { resolve } from "path";

const API_ROOT_URL = "https://api.evtgo.ca/"
export async function updateUserInfoLocal(){
    if (auth.currentUser != null){
        const uid = auth.currentUser.uid
        let doccRef = doc(fs,"users",uid)
        getDoc(doccRef).then((snapshot) => {
            if (snapshot.exists()){
              const userData = snapshot.data()
              const userDataString = JSON.stringify({"user": userData})
              localStorage.setItem("user",userDataString)
              if (userData.orgId != null && userData.orgId != ""){
                localStorage.setItem("org_id",userData.orgId)
                if (userData.orgName != null){
                  localStorage.setItem("org_name",userData.orgName)
                }
                if (userData.profileURL != null){
                  localStorage.setItem("user_profile",userData.profileURL)
                }
              }
              window.location.reload()
            }
            else{
              window.location.reload()
            }
          })
    }
    
}

export async function joinNewsLetter(email: string){
    fetch(`https://api.evtgo.ca/v1JoinEventGoNewsLetter?email=${email}`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        })
        .then(response => response.json())
        .then((data) => {
            if (data.success != null && data.success){
                alert("Congrats! You have successfully joined our newsletter")
            }
            else{
                alert("Email already exist")
            }
        })
}

export async function joinNewsLetterWithResult(email: string){
    return new Promise((reslove, reject)=> {
        fetch(`https://api.evtgo.ca/v1JoinEventGoNewsLetter?email=${email}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            })
            .then(response => response.json())
            .then((data) => {
                if (data.success != null && data.success){
                    reslove(true)
                }
                else{
                    reject('Email already exist')
                }
            })   
    })
}

export async function contactUs(email:string, name:string, message: string){
    addDoc(collection(fs,'mail'),{
        to: ['contact@evtgo.com'],
        from: `${name} <contact@evtgo.com>`,
        message: {
            subject: `New Message - ${email}`,
            text: `Name: ${name}\n\nMessage: ${message}`
        }
    }).then((docRe) => {
        alert("We have received your message!")
    })
}

export async function buttonClickRecorded(toStr: string, currentIP: string){
    const viewId = localStorage.getItem("viewId") || ""
    let currentURL = window.location.href
    let userUID = ""
    if (auth.currentUser != null){
        userUID = auth.currentUser.uid
    }
    logEvent(analytics,"page_view",{page_path: window.location.href, page_title: toStr})
}

export async function eventGoFreeItemsCheckout(
    eventId:string, 
    couponCode:string,
    firstName: string,
    lastName: string,
    email: string,
    uniqueId:string,
    formSubmissionId:string,){
        const user_uid = auth.currentUser?.uid || ""
        if (user_uid != ""){
            fetch(`${API_ROOT_URL}v1EventsAPIIssueFreeTickets?user_id=${user_uid}&event_id=${eventId}&unique_id=${uniqueId}&first_name=${firstName}&last_name=${lastName}&email=${email}&coupon=${couponCode}&form_submission_id=${formSubmissionId}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                }).then(response => response.json())
                .then((data) => {
                    let dataObj = {
                        status: 'failure',
                        success: false,
                        orderId: '',
                    }
                    dataObj = data
                    if (dataObj.success && dataObj.orderId != ""){
                        //reset
                        localStorage.removeItem('login_redirect')
                        localStorage.removeItem('eventView')
                        window.location.replace("/order-confirmation/" + dataObj.orderId)
                    }
                    else {
                        window.location.href = "/e/"+eventId
                    }
                })
        }
        
}

export async function eventGoFormSubmission(
    eventId:string, 
    email: string, 
    firstName:string, 
    lastName:string, 
    formContent: any[]){
    const docRef = await addDoc(collection(fs, "events", eventId, "formSubmissions"), {
        forms: formContent,
        email: email,
        firstName: firstName,
        lastName: lastName,
        timestamp: Timestamp.fromDate(new Date()),
    })
    const submissionId = docRef.id
    return submissionId
}

export async function eventGoRequestSubmission(
    eventId:string, 
    email: string, 
    firstName:string, 
    lastName:string,
    fromUA: string,
    formSubmissionId: string, 
    formContent: any[],
    priceId: string,
    quantity: number,
    userUID: string){
    const docRef = await addDoc(collection(fs, "events", eventId, "requests"), {
        email: email,
        firstName: firstName,
        lastName: lastName,
        userUID: userUID,
        priceId: priceId,
        quantity: quantity,
        form: formContent,
        fromUA: fromUA,
        formSubmissionId: formSubmissionId,
        status: 'request',
        type: 'join-request',
        timestamp: Timestamp.fromDate(new Date()),
    })
    const requestId = docRef.id
    return requestId
}

export async function eventGoAddTourTicketToCartAndPay(
    eventId:string,
    uniqueId:string,
    dateSelected: string,
    firstName: string,
    lastName: string,
    email: string,
    eventTitle: string,
    photoURL: string,
    orgId: string,
    couponCode: string,
    promoStripe: string,
    promoOption: string,
    formSubmissionId: string,
    cart_items: any[]
){
    if (auth.currentUser != null){
        const user_uid = auth.currentUser.uid
        //add to cart
        setDoc(doc(fs,"users",user_uid,"carts",uniqueId),{
            eventId: eventId, 
            uniqueId: uniqueId,
            timestamp: Timestamp.fromDate(new Date()),
            shoppingCart: cart_items,
            status: 'pending',
        }).then((e)=>{
            let cartAllFree = true
            let payment_items: any[] = []
            cart_items.forEach((orderItm) => {
                if (orderItm.price > 0){
                    cartAllFree = false
                }
                let priceId = ""
                let quantity = 0
                if (orderItm.priceId != null){
                    priceId = orderItm.priceId
                }
                if (orderItm.quantity != null){
                    quantity = orderItm.quantity
                }
                if (priceId != "" && quantity > 0){
                    payment_items.push({
                        price: priceId,
                        quantity: quantity,
                    })
                }
            })
            if (cartAllFree || (promoOption == "FREE" && couponCode != "")){
                eventGoFreeItemsCheckout(
                    eventId,
                    couponCode,
                    firstName,
                    lastName,
                    email,
                    uniqueId,
                    formSubmissionId)
            }
            else{
                if (payment_items.length > 0){
                    eventGoTourTicketShoppingCartPayment(
                        user_uid,
                        eventId,
                        eventTitle,
                        false,
                        firstName + " " + lastName,
                        firstName,
                        lastName,
                        cart_items,
                        payment_items,
                        email,
                        dateSelected,
                        orgId,
                        false,
                        couponCode,
                        promoStripe,
                        photoURL,
                        uniqueId,
                        formSubmissionId
                    )
                }
            }


        })
    }
}

export async function eventGoAddToCartAndPay(
    eventId:string,
    uniqueId:string,
    firstName: string,
    lastName: string,
    email: string,
    eventTitle: string,
    photoURL: string,
    orgId: string,
    couponCode: string,
    promoStripe: string,
    promoOption: string,
    formSubmissionId: string,
    cart_items: any[]){
    if (auth.currentUser != null){
        const user_uid = auth.currentUser.uid
        //add to cart
        setDoc(doc(fs,"users",user_uid,"carts",uniqueId),{
            eventId: eventId, 
            uniqueId: uniqueId,
            timestamp: Timestamp.fromDate(new Date()),
            shoppingCart: cart_items,
            status: 'pending',
        }).then((e)=>{
            //pay
            let cartAllFree = true
            let payment_items: any[] = []
            cart_items.forEach((orderItm) => {
                if (orderItm.price > 0){
                    cartAllFree = false
                }
                let priceId = ""
                let quantity = 0
                if (orderItm.priceId != null){
                    priceId = orderItm.priceId
                }
                if (orderItm.quantity != null){
                    quantity = orderItm.quantity
                }
                if (priceId != "" && quantity > 0){
                    payment_items.push({
                        price: priceId,
                        quantity: quantity,
                    })
                }
            })
            //if cart items are free
            //or promocode is free / coupon is not empty
            console.log(promoOption + " - " + couponCode)
            if (cartAllFree || (promoOption == "FREE" && couponCode != "")){
                eventGoFreeItemsCheckout(
                    eventId,
                    couponCode,
                    firstName,
                    lastName,
                    email,
                    uniqueId,
                    formSubmissionId)
            }
            else{
                if (payment_items.length > 0){
                    eventGoPayShoppingCartPayment(
                        user_uid,
                        eventId,
                        eventTitle,
                        false,
                        firstName + " " + lastName,
                        firstName,
                        lastName,
                        cart_items,
                        payment_items,
                        email,
                        '',
                        orgId,
                        false,
                        couponCode,
                        promoStripe,
                        photoURL,
                        uniqueId,
                        formSubmissionId
                    )
                }
                else{
                    alert('no payment items found')
                }
            }
            
        }).catch((e)=>{
            alert('something wrong with cart')
        })
    }
    else{
        signInAnonymously(auth).then((userRef) => {
            const user_uid = userRef.user.uid
            localStorage.setItem("user_uid",user_uid)
            userRef.user.getIdToken(true).then((idToken)=>{
                if (idToken != ""){
                  localStorage.setItem('user_token',idToken)
                }
            })
            let userDataa = {
                'firstName':firstName,
                'lastName':lastName,
                'email':email,
                'currentEvent': eventId,
                'lang': 'en',
                'userName': firstName + " " + lastName,
                'name': firstName + " " + lastName,
                'profileURL':'',
                'isAnonymous': true,
                'timestamp': Timestamp.fromDate(new Date()),
            }
            
            const userDataString = JSON.stringify({"user": userDataa})
            localStorage.setItem("user",userDataString)

            setDoc(doc(fs,'users',user_uid),userDataa).then((vv)=>{
                setDoc(doc(fs,"users",user_uid,"carts",uniqueId),{
                    eventId: eventId, 
                    uniqueId: uniqueId,
                    timestamp: Timestamp.fromDate(new Date()),
                    shoppingCart: cart_items,
                    status: 'pending',
                }).then((e)=>{

                    //pay
            let cartAllFree = true
            let payment_items: any[] = []
            cart_items.forEach((orderItm) => {
                if (orderItm.price > 0){
                    cartAllFree = false
                }
                let priceId = ""
                let quantity = 0
                if (orderItm.priceId != null){
                    priceId = orderItm.priceId
                }
                if (orderItm.quantity != null){
                    quantity = orderItm.quantity
                }
                if (priceId != "" && quantity > 0){
                    payment_items.push({
                        price: priceId,
                        quantity: quantity,
                    })
                }
            })
            if (cartAllFree || (promoOption == "FREE" && couponCode != "")){
                eventGoFreeItemsCheckout(
                    eventId,
                    couponCode,
                    firstName,
                    lastName,
                    email,
                    uniqueId,
                    formSubmissionId)
            }
            else{
                if (payment_items.length > 0){
                    eventGoPayShoppingCartPayment(
                        user_uid,
                        eventId,
                        eventTitle,
                        false,
                        firstName + " " + lastName,
                        firstName,
                        lastName,
                        cart_items,
                        payment_items,
                        email,
                        '',
                        orgId,
                        false,
                        couponCode,
                        promoStripe,
                        photoURL,
                        uniqueId,
                        formSubmissionId
                    )
                }
                else{
                    alert('no payment items found')
                }
            }

                    
                }).catch((e)=>{
                    alert('something wrong with cart')
                })
            }).catch((e) => {
                alert("something wrong with cart")
            })
        
        })
    }
}

export async function eventGoCheckout(
    firstName: string,
    lastName: string,
    email: string,
    eventId: string,
    eventName: string,
    uniqueId:string,
    orgId:string,
){
    if (auth.currentUser != null){
        //user already login
        const user_uid = auth.currentUser.uid
        fetch(`${API_ROOT_URL}v1EventsAPIIssueFreeTickets?user_uid=${user_uid}&event_id=${eventId}&unique_id=${uniqueId}&first_name=${firstName}&last_name=${lastName}&email=${email}`,{
            headers: {
              "Content-Type": "application/json",
            },}).then((response) => {

        })
    }
    else{
        //create guest account
    }
}

export async function eventGoTourTicketShoppingCartPayment(
    uid:string,
    eventId:string, 
    eventName:string, 
    calculateTax:boolean,
    userName:string,
    userFirstName:string,
    userLastName:string,
    cart_items:any[],
    payment_items:any[],
    userEmail:string,
    dateSelected:string,
    orgId:string,
    applyCoupon:false,
    eventGoCoupon: string, 
    stripeCoupon: string,
    photoURL: string,
    uniqueId:string,
    formSubmissionId:string
){

    const docRef = await addDoc(collection(fs, "service-stripe-customers", uid, "checkout_sessions"), 
    {   
        mode: "payment",
        automatic_tax: calculateTax,
        eventId: eventId,
        line_items: payment_items,
        allow_promotion_codes: true,
        promotion_code: stripeCoupon,
        success_url: `${API_ROOT_URL}v1EventsAPIIssueTourTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
        cancel_url: window.location.origin + '/e/'+eventId,
        metadata: {
            eventId: eventId,
            eventTitle: eventName,
            orgId: orgId,
            eventName: eventName,
            firstName: userFirstName,
            lastName: userLastName,
            userUID: uid,
            url: `${API_ROOT_URL}v1EventsAPIIssueTourTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
            fromUA: window.navigator.userAgent.toLocaleLowerCase(),
            registerEmail: userEmail,
            registerName: userName,
            uniqueId: uniqueId,
            dateSelected: dateSelected,
            calculateTax: calculateTax,
            type: 'tour',
            photoURL: photoURL,
            formSubmissionId: formSubmissionId,
            requestId: '',
            promoCode: eventGoCoupon,
            promoStripe: stripeCoupon,
            redirectURL: window.location.origin + '/order-confirmation/'+uniqueId

                
            },
        })

        onSnapshot(docRef, (docR) => {
            const docId = docR.id
            const docRData = docR.data() || {};
            const error = docRData.error;
            const url = docRData.url

            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: could not purchase ticket`)
            }
            if (url) {
                localStorage.removeItem('login_redirect')
                localStorage.removeItem('eventView')
                window.location.assign(url)
            }
        })

}

export async function eventGoPayShoppingCartPayment(
    uid:string,
    eventId:string, 
    eventName:string, 
    calculateTax:boolean,
    userName:string,
    userFirstName:string,
    userLastName:string,
    cart_items:any[],
    payment_items:any[],
    userEmail:string,
    dateSelected:string,
    orgId:string,
    applyCoupon:false,
    eventGoCoupon: string, 
    stripeCoupon: string,
    photoURL: string,
    uniqueId:string,
    formSubmissionId:string){

        const docRef = await addDoc(collection(fs, "service-stripe-customers", uid, "checkout_sessions"), 
        {   mode: "payment",
            automatic_tax: calculateTax,
            eventId: eventId,
            line_items: payment_items,
            allow_promotion_codes: applyCoupon,
            promotion_code: stripeCoupon,
            success_url: `${API_ROOT_URL}v1EventsAPIIssuePaidTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
            cancel_url: window.location.origin + '/e/'+eventId,
            metadata: {
                eventId: eventId,
                eventTitle: eventName,
                orgId: orgId,
                eventName: eventName,
                firstName: userFirstName,
                lastName: userLastName,
                userUID: uid,
                url: `${API_ROOT_URL}v1EventsAPIIssuePaidTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
                fromUA: window.navigator.userAgent.toLocaleLowerCase(),
                registerEmail: userEmail,
                registerName: userName,
                uniqueId: uniqueId,
                dateSelected: dateSelected,
                calculateTax: calculateTax,
                photoURL: photoURL,
                formSubmissionId: formSubmissionId,
                requestId: '',
                promoCode: eventGoCoupon,
                promoStripe: stripeCoupon,
                redirectURL: window.location.origin + '/order-confirmation/'+uniqueId
            },
        });

        onSnapshot(docRef, (docR) => {
            const docId = docR.id
            const docRData = docR.data() || {};
            const error = docRData.error;
            const url = docRData.url

            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: could not purchase ticket`)
            }
            if (url) {
                localStorage.removeItem('login_redirect')
                localStorage.removeItem('eventView')
                window.location.assign(url)
            }
        })

        
}


function eventGoAPI(){

}

export default eventGoAPI