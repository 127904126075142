import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { NewEventFormType, NewEventAgendaType, NewEventCouponType, NewEventTicketType, EventDataType } from "data/types";
import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import EventCard from "components/EventCard/EventCard";
import {auth, fs} from "firebaseSetup";
import { getDoc, getDocs, collection, doc, setDoc, Timestamp, addDoc } from "firebase/firestore"

export interface PageCreateEventProps {}

const PageCreateEventPreview: FC<PageCreateEventProps> = () => {

  const [eventLoading, setEventLoading] = useState(false)

  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    startDateStr: '',
    endDateStr: '',
    photoURL: '',
    photoURLs: [],
    address: {
      fullAddress: '',
      city: '',
      street: '',
      state: '',
      country: '',
      postalCode: '',
      name: '',
      unit: '',
      placeId: '',
      lat: 0.0,
      lng: 0.0,
    },
    price: 0,
    tags: [],

  })

  const [eventPreview, setEventPreview] = useState<EventDataType>({
    id: '',
  title: '',
  description: '',
  startDateString: '',
  endDateString: '',
  photoURLs: [],
  photoURL: '',
  viewCount: 0,
  location: '',
  address: '',
  price: 0,
  tags: [],
  type: 'event',
  priceDiscount: null,
  })

  const [tickets, setTickets] = useState<NewEventTicketType[]>([])
  const [coupons, setCoupons] = useState<NewEventCouponType[]>([])
  const [agendas, setAgendas] = useState<NewEventAgendaType[]>([])
  const [forms, setForms] = useState<NewEventFormType[]>([])

  const [orgInfo, setOrgInfo] = useState({
    name: '',
    description: '',
    imageURL: '',
    orgId: '',
    newOrg: true,
    emtEmail: '',
  })
  

  useEffect(()=>{
    const eventBasicItem = localStorage.getItem('create_event_basics') || '{}'
    if (eventBasicItem != '{}'){
      
      let eventBasics = JSON.parse(eventBasicItem) || {}


      var eventD = {
        title: eventBasics.title,
    description: eventBasics.description,
    startDateStr: eventBasics.startDateStr,
    endDateStr: eventBasics.endDateStr,
    photoURL: eventBasics.photoURL,
    photoURLs: eventBasics.photoURLs,
    address: eventBasics.address,
    price: eventBasics.price,
    tags: eventBasics.tags,
      }
      
      setEventData({...eventD})
      
      setEventPreview({
        id: '',
        title: eventBasics.title,
        description: eventBasics.description,
        startDateString: eventBasics.startDateStr,
        endDateString: eventBasics.endDateStr,
        photoURLs: [eventBasics.photoURL],
        photoURL: eventBasics.photoURL,
        viewCount: 0,
        location: eventBasics.address.city,
        address: eventBasics.address.fullAddress,
        price: -1,
        tags: [],
        type: 'event',
        priceDiscount: null,
      })

      
    }
    else {
      window.location.assign('/create-event')
    }

    const eventTicketsItem = localStorage.getItem('create_event_tickets') || '{}'
    if (eventTicketsItem != '{}'){
      let eventTicketD = JSON.parse(eventTicketsItem) || {prices: []}
      setTickets(eventTicketD.prices)
      if (eventTicketD.prices.length >= 1){
        //setEventData({...eventData, price: eventTicketD.prices[0].price})
      }
      else{
        window.location.assign('/create-event-02')
      }
    }
    else{
      window.location.assign('/create-event-02')
    }
    const eventCouponsItem = localStorage.getItem('create_event_coupons') || '{}'
    if (eventCouponsItem != '{}'){
      let eventCouponD = JSON.parse(eventCouponsItem) || {coupons: []}
      setCoupons(eventCouponD.coupons)
    }
    const eventAgendaItem = localStorage.getItem('create_event_agendas') || '{}'
    if (eventAgendaItem != '{}'){
      let eventAgendaD = JSON.parse(eventAgendaItem) || {agendas: []}
      setAgendas(eventAgendaD.agendas)
    }
    const eventFormItem = localStorage.getItem('create_event_forms') || '{}'
    if (eventFormItem != '{}'){
      let eventFormD = JSON.parse(eventFormItem) || {forms: []}
      setForms(eventFormD.forms)
    }

    

    //getting the event data
    gettingUserOrgInfo()
    



  },[])


  async function gettingUserOrgInfo(){
    if (auth.currentUser != null){
      let userOrgId = ""
      const userUID = auth.currentUser?.uid
      let doccRef = doc(fs,"users",userUID)
        getDoc(doccRef).then((snapshot) => {
          if (snapshot.exists()){
            let userD = snapshot.data()
            if (userD.orgId != null){
              if (userD.orgId != ""){
                //has org
                userOrgId = userD.orgId
              }
            }
            if (userOrgId != ""){
              let groupRef = doc(fs,"groups",userOrgId)
              getDoc(groupRef).then((groupSnap) => {
                if (groupSnap.exists()){
                  const groupD = groupSnap.data()
                  setOrgInfo({
                    name: groupD.name,
                    description: groupD.description,
                    imageURL: groupD.profileURL,
                    orgId: userOrgId,
                    newOrg: false,
                    emtEmail: groupD.info.email,
                  })
                  console.log(orgInfo)
                }
                else{
                  let eventOrgItem = localStorage.getItem('create_event_orgs') || '{}'
                if (eventOrgItem != '{}'){
                  let eventOrgD = JSON.parse(eventOrgItem)
                  setOrgInfo(eventOrgD)
                }
                console.log(orgInfo)
                }
              })
            }
            else{
              let eventOrgItem = localStorage.getItem('create_event_orgs') || '{}'
              if (eventOrgItem != '{}'){
                let eventOrgD = JSON.parse(eventOrgItem)
                setOrgInfo(eventOrgD)
              }
              else{
                window.location.assign('/create-event-05')
              }
            }
          }
        })
      }
  }


  async function createAndSubmitEvent(){
    //step 1: check if all requrement meet
    //Event Location, Event Date and TIme
    let startD = new Date(eventData.startDateStr)
    let endD = new Date(eventData.endDateStr)

    const checkTitle = eventData.title != ""
    const checkDescription = eventData.description != ""
    const checkPhoto = eventData.photoURL != ""
    const checkAddress = eventData.address.fullAddress != ""
    const checkTicket = tickets.length > 0
    const checkDate = startD <= endD && startD >= (new Date())
    const checkProfile = (orgInfo.orgId != "" && orgInfo.emtEmail != "" && orgInfo.name != "")
    

    if (checkTitle && 
        checkDescription && 
        checkPhoto &&
        checkAddress &&
        checkTicket &&
        checkProfile &&
        checkDate)
    {

      let descriptions = eventData.description.split("\n")

      let priceItm = tickets[0]

      let systemAgendas: any[] = []
      agendas.forEach((agendaItm) => {
        let dateObj = Timestamp.fromDate(new Date(agendaItm.startDate))
        systemAgendas.push({...agendaItm,date: dateObj})
      })

      let systemTickets: any[] = []
      tickets.forEach((priceItm) => {
        let saleStart = Timestamp.fromDate(new Date(priceItm.saleStartStr))
        let saleEnd = Timestamp.fromDate(new Date(priceItm.saleEndStr))
        systemTickets.push({...priceItm, saleStart: saleStart, saleEnd: saleEnd})
      })

      
      

      const eventDraftRef = await addDoc(collection(fs, "event_drafts"), {
        ...eventData, 
        price: priceItm.price, 
        descriptions: {en: descriptions, zh: descriptions},
        startDate: Timestamp.fromDate(startD),
        endDate: Timestamp.fromDate(endD),
        status: "draft",
        tickets: systemTickets,
        coupons: coupons,
        agendas: systemAgendas,
        forms: forms,
        fromUID: auth.currentUser?.uid || "",
        orgId: orgInfo.orgId,
        url: window.location.origin,
        fromUA: window.navigator.userAgent.toLowerCase(),
        user: {
          email: auth.currentUser?.email || orgInfo.emtEmail ,
          imageURL: orgInfo.imageURL,
          uid: auth.currentUser?.uid || "",
          userName: orgInfo.name,
          userOrg: orgInfo.name,
        },
        timestamp: Timestamp.fromDate(startD)});
      
        const eventDraftId = eventDraftRef.id
        if (eventDraftId != ""){
          if (orgInfo.newOrg){
            //create org and publish
            //user token
            auth.currentUser?.getIdToken(true).then((userToken) => {
              //create org
            const body = `org_id=${orgInfo.orgId}&org_name=${orgInfo.name}&org_emt_email=${orgInfo.emtEmail}&org_profile_url=${orgInfo.imageURL}&event_draft_id=${eventDraftId}`
            fetch(`https://api.evtgo.ca/v1OrganizerAPICreateOrganization?${body}`,{
              headers: {
                  "Content-Type": "application/json",
                  'Access-Control-Allow-Origin': '*',
                  "Authorization": "Bearer " + userToken
                },
            }).then((response) => {
             const responseTask = response.json()
             responseTask.then((data) => {
               if (data.status != null && data.status == "success"){

                localStorage.removeItem('create_event_basics')
                localStorage.removeItem('create_event_agendas')
                localStorage.removeItem('create_event_coupons')
                localStorage.removeItem('create_event_tickets')
                localStorage.removeItem('create_event_orgs')
                window.location.assign("/account-organization")
               }
               else{
                setEventLoading(false)
                alert("Event creation failed")
               }
             })})
              
            })
            
          }
          else{
            //publish and draft
            //clean draft
            localStorage.removeItem('create_event_basics')
            localStorage.removeItem('create_event_agendas')
            localStorage.removeItem('create_event_coupons')
            localStorage.removeItem('create_event_tickets')
            localStorage.removeItem('create_event_orgs')
            window.location.assign("/account-organization")
          }
          
        }
        else {
          //something wrong
          setEventLoading(false)
          alert("Event creation failed")
        }
        //create org
    }
    else{
      setEventLoading(false)
      if (!checkTitle){
        alert("Title is not correct")
      }
      if (!checkDescription){
        alert("Description is not correct")
      }
      if (!checkPhoto){
        alert("Photo is not correct")
      }
      if (!checkAddress){
        alert("Address is not correct")
      }
      if (!checkTicket){
        alert("Ticket is not correct")
      }
      if (!checkProfile){
        alert("Profile is not correct")
      }
      if (!checkDate){
        alert("Date is not correct")
      }
    }


    //step 2: If org is new, create org now
    
  }
  
  return (
    <CommonLayout
      nextBtnText="Submit for review"
      index="06"
      backtHref="/create-event-05"
      nextHref="/"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">One More Step</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Excellent, now you have completed making the event, it is waiting
            to be reviewed for publication
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">This is your event preview</h3>
          <div className="max-w-xs">
            <EventCard className="mt-8" key={eventPreview.id} data={eventPreview} />
          </div>
          <div className="flex items-center space-x-5 mt-8">
            <ButtonSecondary href="/create-event">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Edit</span>
            </ButtonSecondary>

            <ButtonPrimary>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              <span className="ml-3">Preview</span>
            </ButtonPrimary>
          </div>

          <div className="space-y-6 mt-10">
          <h3 className="text-2xl font-semibold">Event Details</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Event Name</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {eventData.title}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Event Description</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {eventData.description}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Start Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {eventData.startDateStr.replace('T', ' ')}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">End Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {eventData.endDateStr.replace('T', ' ')}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Location</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {eventData.address.fullAddress}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Tickets</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {tickets.map((tick, index) => {
                  return (
                  <div>
                  <div>Ticket {index+1}: {tick.priceDescription}</div>
                  <div>Price: {tick.price}</div>
                  </div>
                  )
                })}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Agenda</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {agendas.length}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Coupons</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {coupons.length}
              </span>
            </div>

            </div>
          </div>
          
          <div className="flex justify-end space-x-5 mt-6">
            <ButtonPrimary loading={eventLoading} onClick={()=>{
              //submit
              setEventLoading(true)
              createAndSubmitEvent()
            }}>Submit for Review</ButtonPrimary>
          </div>
        </div>
        {/*  */}
      </>
    </CommonLayout>
  );
};

export default PageCreateEventPreview;
