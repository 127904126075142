import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";
import EventCard from "components/EventCard/EventCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useEffect, useState } from "react";
import { auth } from "firebaseSetup";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { EventDataType, EventOrderType } from "data/types";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Paper, Typography, Badge, Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, Avatar, IconButton, Button } from '@mui/material'
import ButtonPrimary from "shared/Button/ButtonPrimary";
import EventOrderCard from "components/EventCard/EventOrderCard";

interface OrderViewProps {
    loading?: boolean;
    orderDetails?: EventOrderType;
  }

  function LoadingOrderView(){
    return (
      <Card sx={{ maxWidth: 345, m: 2 }}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
        <CardContent>
          <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
        </CardContent>
      </Card>
    )
  }

  function OrderLoadingView(){
    return (
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <LoadingOrderView />
            <LoadingOrderView />
            <LoadingOrderView />
            <LoadingOrderView />
            <LoadingOrderView />
            <LoadingOrderView />
            <LoadingOrderView />
            <LoadingOrderView />
      </div>
    )
  }
  
  function OrderView(props: OrderViewProps) {
    const { loading = false } = props;
  
    return (
      <Card sx={{ maxWidth: 345, m: 2 }}>
        <CardHeader
          avatar={
            loading ? (
              <Skeleton animation="wave" variant="circular" width={40} height={40} />
            ) : (
              <Avatar
                alt="Ted talk"
                src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg"
              />
            )
          }
          action={
            loading ? null : (
              <IconButton aria-label="settings">
                 <MoreVertIcon />
              </IconButton>
            )
          }
          title={
            loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              'Order # ' + props.orderDetails?.id || ""
            )
          }
          subheader={
            loading ? (
              <Skeleton animation="wave" height={10} width="40%" />
            ) : (
              'Ordered Today'
            )
          }
        />
        {loading ? (
          <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
        ) : (
          <CardMedia
            component="img"
            height="140"
            image="https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/72bda89f-9bbf-4685-910a-2f151c4f3a8a/NicolaSturgeon_2019T-embed.jpg?w=512"
            alt="Nicola Sturgeon on a TED talk stage"
          />
        )}
        <CardContent>
          {loading ? (
            <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          ) : (
            <div>
            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
              {
                (props.orderDetails?.amount || 0).toLocaleString('en-US',{style: "currency", currency: 'CAD'})
              }
            </Typography>
            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
            {
              props.orderDetails?.eventId || ''
            }
          </Typography>

          </div>
          )}
        </CardContent>
        <CardActions>
          <Button 
            href={"/order-confirmation/" + (props.orderDetails?.id || "")} 
            size="small" 
            color="primary">View Details</Button>
          <Button size="small" color="error">Modify</Button>
      </CardActions>
      </Card>
    );
  }
  
  function OrderItem(orderDetails: EventOrderType) {
    return (
      <div>
        <OrderView orderDetails={orderDetails}/>
      </div>
    );
  }

const AccountOrders = () => {
  let [categories] = useState(["Upcoming","Past","Cancelled"]);
  let [events, setEvents] = useState<EventDataType[]>([])
  let [orders, setOrders] = useState<EventOrderType[]>([])
  const [loading, setLoading] = useState<Boolean>(false)

  async function loadUserOrders(userToken:string){
    setLoading(true)
    fetch("https://api.evtgo.ca/v1UserAuthenticatedGetUserOrders", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ userToken
        },
      })
      .then(response => response.json())
      .then((data) => {
          setLoading(false)
          if (data.success != null){
              if (data.success){
                  if (data.orders != null){
                      let orderss = data.orders
                      setOrders(orderss)
                  }
              }
              else{
                //
                if (data.error != null){
                  if (data.error == 'token'){
                    auth.currentUser?.getIdToken(true).then((idToken) => {
                      if (idToken != ""){
                        localStorage.setItem('user_token',idToken)
                        loadUserOrders(idToken)
                      }
                    })
                  }
                }
                
              }
          }
          else{}
      })
  }
  useEffect(()=>{
    const userToken = localStorage.getItem('user_token')
    if (userToken != null && userToken != ""){
      loadUserOrders(userToken)
    }
    else{
      auth.currentUser?.getIdToken(true).then((idToken) => {
        if (idToken != ""){
          localStorage.setItem('user_token',idToken)
          loadUserOrders(idToken)
        }
      })
    }
    
  },[])

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">All Orders</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
            <Tab.Panel className="mt-8">
            {orders.length == 0 && loading && <OrderLoadingView/> }
            {orders.length == 0 && !loading && <div>You don't have any orders</div>}
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                
                {
                    orders.map((item,index) => {
                        return <EventOrderCard data={item} />
                    })
                }
                
            </div>
              </Tab.Panel>
              <Tab.Panel className="mt-8">
              {orders.length == 0 && loading && <OrderLoadingView/> }
              {orders.length == 0 && !loading && <div>You don't have any orders</div>}
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                
                </div>
              </Tab.Panel>
              
              <Tab.Panel className="mt-8">
                {orders.length == 0 && loading && <OrderLoadingView/> }
                {orders.length == 0 && !loading && <div>You don't have any orders</div>}
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountOrders;
