import { useEffect, useState } from "react";
import { EventDataType } from "data/types";
import { auth, fs} from "./../firebaseSetup"
import { getAuth, createUserWithEmailAndPassword, signInAnonymously } from "@firebase/auth";

/**
 * Hook that load event data
 */

async function loadAdminIdToken(){
  let idToken = ""
  const userToken = localStorage.getItem('user_token')
  if (userToken != null && userToken != ""){
    idToken = userToken
    
  }
  else{
    const newIdToken = await auth.currentUser?.getIdToken(true)
    if (newIdToken != null && newIdToken != ""){
      idToken = newIdToken
    }
    else{
    }
  }
  return idToken
  
}
function useEventDashboardLoader(
    {
        isLoading,
        eventId,
        mode,
        handleCompleteCallback,
      }: {
        isLoading: boolean;
        eventId: string;
        mode: string;
        handleCompleteCallback: () => void;
      }) {
    let timeOut: NodeJS.Timeout | null = null;
    const [dashboardInfo, setDashboardInfo] = useState({
        dashboardItems: [
            {
                title: '',
                total: 0,
                time: '',
                notice: '',
            }
        ],
        eventItem: {
            title: 'Loading...',
            id: '',
            description: 'Loading...'
        },
        currentTime: 'Loading...',
        mode: mode,
        tickets: [],
        members: [],
        orders: [],
    })
    
    useEffect(() => {
      console.log('triggered');
        if (isLoading) {
            return;
        }
          //login to firebase
          
          let userUID = ""
            userUID = auth.currentUser?.uid || ""
            let userToken = localStorage.getItem('user_token') || ""
            
            if (userToken != null && userToken != ""){

              fetch(`https://api.evtgo.ca/v1OrganizerAuthenticatedGetOrganizerDetailedEvents?event_id=${eventId}&full_data=${true}&mode=${mode}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userToken
              },
  
       }).then((response) => {
           const responseTask = response.json()
           responseTask.then((data) => {
               let dashboardItem = dashboardInfo
               if (data.success != null && data.success){
                   //load data
                   if (data.currentTime != null){
                       dashboardItem.currentTime = data.currentTime
                   }
                   
                   if (data.overview != null){
                       console.log(data.overview)
                       let overview = {...data.overview}
                       dashboardItem.dashboardItems = [
                           {
                             title: 'Total Sales',
                             total: overview.totalSales + "CAD",
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Tickets Sold',
                             total: overview.tickets,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Members',
                             total: overview.members,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Page Views',
                             total: overview.views,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Form Submissions',
                             total: overview.formSubmissions,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Invoices',
                             total: overview.invoices,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                         ]
                   }
                   
                   if (data.event != null){
                       dashboardItem.eventItem = data.event
                   }
                   if (data.tickets != null && mode == "tickets"){
                       if (data.tickets.data != null){
                          dashboardItem.tickets = data.tickets.data
                       }
                   }
                   if (data.members != null && mode == "members"){
                    if (data.members.data != null){
                       dashboardItem.members = data.members.data
                    }
                  }

                   setDashboardInfo(dashboardItem)


                   return dashboardInfo
               }
               

           })
       })


            }
          else{

          }

          
          return () => {
            timeOut && clearTimeout(timeOut);
          };
    
  }, [dashboardInfo]);

  return dashboardInfo;
}

export default useEventDashboardLoader;
