import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import GuestsInput from "./../../components/HeroSearchForm2Mobile/GuestsInput";
import { QRCodeCanvas } from 'qrcode.react'

export interface EventTicketPageMainProps {
  className?: string;
}

const EventTicketPage: FC<EventTicketPageMainProps> = ({
  className = "",
}) => {

    const { id } = useParams()
    const [ticketId, setTicketId] = useState<string>(id || "")
    const [eventId, setEventId] = useState<string>('')

    const [ticketDetails, setTicketDetails] = useState({
        eventId: '',
        eventOrgName: 'Loading...',
        eventTitle: 'Loading...',
        validated: false,
        userName: '',
        fromUID: '',
        tickets: [],
        quantity: 1,
        checkin: false,
    })
  
    const [eventDetails, setEventDetails] = useState({});
    const [orgDetails, setOrgDetails] = useState({});

    useEffect(()=>{
        //load ticket details
        fetch("https://api.evtgo.ca/v1EventsAPIFetchTicketDetails?ticket_id="+ticketId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
      })
      .then(response => response.json())
      .then((data) => {
        if (data.data != null){
            let ticketData = {quantity: 1, tickets: []}
            ticketData = data.data
            var quan = 1
            var tickets: string[] = []
            if (ticketData.quantity != null){
              quan = ticketData.quantity
            }
            if (ticketData.tickets != null){
              tickets = ticketData.tickets
            }
            setTicketDetails(data.data)
            if (data.eventData != null){
              let eventData = {}
              eventData = data.eventData
              setEventDetails({...eventData})
            }
            setEventId(data.data.eventId)
          }
          else{

          }

      })
        
    },[])

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                Event Type
              </span>
              <span className="text-base font-medium mt-1 block">
                {ticketDetails.eventTitle}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {ticketDetails.eventOrgName}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        
        <div className="pt-8">
              <ButtonPrimary href={"/"+eventId}>View Event</ButtonPrimary>
            </div>
        {/* <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$57</span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Ticket #{ticketId}
        </h2>
      <div>
          
          <div className="mt-3">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Ticket Details
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">FAQ</span>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                <div className="mt-10">
                <div>Ticket QR Code</div>
                <QRCodeCanvas
                                    id="qrCodeEl"
                                    value={
                                      "https://tickets.evtgo.com/ticket-id/" + ticketId
                                    }
                                    size={256}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    imageSettings={{
                                      src: '',
                                      height: 20,
                                      width: 20,
                                      excavate: true,
                                    }}
                                  />
                </div>
                  
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary href={"/pay-done"}>Check-In</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default EventTicketPage;
