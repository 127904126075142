import { useEffect, useState } from "react";
import { EventDataType, OrganizerDisplayType, TopBannerType } from "data/types";
import { auth, fs} from "./../firebaseSetup"
import { getAuth, createUserWithEmailAndPassword, signInAnonymously } from "@firebase/auth";

/**
 * Hook that load event data
 */
function useHomePageDataLoader(
    {
        isLoading,
        initEvents,
        newEvents,
        tabActive,
        tabs,
        dateStart,
        dateEnd,
      }: {
        isLoading: boolean;
        initEvents: EventDataType[];
        newEvents?: EventDataType[];
        tabs: string[];
        tabActive: string;
        dateStart?: string;
        dateEnd?: string;
        keyword?: string;
        

      }) {
    let timeOut: NodeJS.Timeout | null = null;
    const [currentEvents, setCurrentEvents] = useState<EventDataType[]>(initEvents);
    const [currentOrganizers, setCurrentOrganizers] = useState<OrganizerDisplayType[]>();
    const [currentTabs, setCurrentTabs] = useState<string[]>([])
    const [currentTabIds, setCurrentTabIds] = useState<string[]>([])
    const [currentBanner, setCurrentBanner] = useState<TopBannerType[]>([])

    useEffect(() => {
        if (isLoading) {
            return;
          }
          //login to firebase
          let userUID = ""
          if (auth.currentUser != null){
            userUID = auth.currentUser.uid
          }
        fetch(`https://api.evtgo.ca/v1EventsAPIFetchHomepage?user_id=${userUID}&count=${10}`,{
         headers: {
           "Content-Type": "application/json",
         },
  
       }).then((response) => {
         const responseTask = response.json();
         responseTask.then((data) => {
           let dataItm = {event: {data: [], count: 0}, organizer: {data: [], count: 0}, tags: {tags: [], tagNames: {}}, banners: {banners: []}}
           dataItm = data
           let newEventData: EventDataType[] = []
           let newOrganizerData: OrganizerDisplayType[] = []
           let newTabs: string[] = []
           let newTabIds: string[] = []

           newTabs.push('All')
           newTabIds.push("")
           dataItm.tags.tags.forEach((tagItm)=>{
             newTabs.push(dataItm.tags.tagNames[tagItm])
             newTabIds.push(tagItm)
           })
           setCurrentTabs(newTabs)
           setCurrentTabIds(newTabIds)

           if (data.viewId != null){
             if (data.viewId != ""){
               localStorage.setItem("viewId",data.viewId)
             }
           }

           localStorage.setItem('tagInfo', JSON.stringify( {'tag_list': dataItm.tags.tags, 'tag_name': dataItm.tags.tagNames}));

           var topBanner: TopBannerType[] = []
           dataItm.banners.banners.forEach((remoteItm: any) => {
             let imageURL = ""
             let link = ""
             if (remoteItm.imageURL != null){
               imageURL = remoteItm.imageURL
             }
             if (remoteItm.link != null){
               link = remoteItm.link
             }
             topBanner.push({image: imageURL, link: link})
           })
           setCurrentBanner(topBanner)

           dataItm.event.data.forEach((remoteItm: any) => {
               let eId = ""
               let eTitle = ""
               let eDescription = ""
               let ePhotoURL = ""
               let ePhotoURLs: string[] = []
               let eAddress = ""
               let eStartDateString = ""
               let eEndDateString = ""
               let ePrice = 0
               let eType = 'event'
               if (remoteItm.type != null){
                 if (remoteItm.type == 'free-event'){
                  eType = 'event'
                 }
                 else if (remoteItm.type == 'event'){
                  eType = 'event'
                 }
                 else if (remoteItm.type == 'tour'){
                   eType = 'tour'
                 }
                 else{
                  eType = 'event'
                 }
                 
               }
              
               if (remoteItm.id != null){
                   eId = remoteItm.id
               }
               if (remoteItm.title != null){
                   eTitle = remoteItm.title
               }
               if (remoteItm.description != null){
                   eDescription = remoteItm.description
               }
               if (remoteItm.startDateString != null){
                   eStartDateString = remoteItm.startDateString
               }
               if (remoteItm.endDateString != null){
                   eEndDateString = remoteItm.endDateString
               }
               if (remoteItm.photoURL != null){
                   ePhotoURL = remoteItm.photoURL
               }
               if (remoteItm.photoURLs != null){
                   ePhotoURLs = remoteItm.photoURLs
                   if (ePhotoURLs.length == 0){
                     ePhotoURLs = [ePhotoURL]
                   }
               }
               else{
                   ePhotoURLs = [ePhotoURL]
               }
               if (remoteItm.price != null){
                   ePrice = remoteItm.price
               }
               if (remoteItm.address != null){
                   if (remoteItm.address.fullAddress != null){
                       eAddress = remoteItm.address.fullAddress
                   }
               }
               let tags = []
               if (remoteItm.tags != null){
                 tags = remoteItm.tags
               }
               let eventDataItem: EventDataType = {
                 id: eId,
                 title: eTitle,
                 description: eDescription,
                 startDateString: eStartDateString,
                 endDateString: eEndDateString,
                 photoURLs: ePhotoURLs,
                 photoURL: ePhotoURL,
                 location: remoteItm.location,
                 viewCount: remoteItm.viewCount,
                 address: eAddress,
                 price: ePrice,
                 tags: tags,
                 type: eType,
                 priceDiscount: remoteItm.priceDiscount
               }
               newEventData.push(eventDataItem)
           })
           setCurrentEvents(newEventData)

           dataItm.organizer.data.forEach((remoteItem: any) => {
             let orgURL = ''
             if (remoteItem.url != null){
               orgURL = remoteItem.url
             }
            let organizerItem: OrganizerDisplayType = {
                id: remoteItem.id,
                name: remoteItem.name,
                description: remoteItem.description,
                profileURL: remoteItem.profileURL,
                location: remoteItem.location,
                country: remoteItem.country,
                event_count: remoteItem.event_count,
                member_count: remoteItem.member_count,
                url: orgURL,
                info: {
                    firstName: remoteItem.info.firstName,
                    lastName: remoteItem.info.lastName,
                    phone: remoteItem.info.phone,
                    work: remoteItem.info.work,
                    name: remoteItem.info.name,
                    wechat: remoteItem.info.wechat,
                    email: remoteItem.info.email
                }
              }
              newOrganizerData.push(organizerItem)
           })
           setCurrentOrganizers(newOrganizerData)

         })
       })
          return () => {
            timeOut && clearTimeout(timeOut);
          };
    
  }, [isLoading]);

  return {events: currentEvents, organizers: currentOrganizers, tabs: currentTabs, tabIds: currentTabIds, banner: currentBanner};
}

export default useHomePageDataLoader;
